.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Regular'), url(./fonts/WorkSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Bold'), url(./fonts/WorkSans-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Italic'), url(./fonts/WorkSans-Italic.ttf) format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Light'), url(./fonts/WorkSans-Light.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Medium'), url(./fonts/WorkSans-Medium.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Recoleta';
  src: local('Latinotype-RecoletaBold'), url(./fonts/Latinotype-RecoletaBold.otf) format('opentype');
}

.grid-heading {
  margin-top:10px;
  font-size: 24px;
  font-family: "WorkSans";
  font-weight: 600;
}

.footer-headings {
  font-family: WorkSans;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-elements {
  font-size: 18px;
  font-family: WorkSans;
  margin-top: 25px;
  color: #25D2AA;
}

.collapse-heading {
  font-size: 24px;
  font-family: WorkSans;
  color:#25D2AA;
  font-weight: 600;
}

.collapse-body {
  font-size: 22px;
  font-family: WorkSans;
}

.verticalLine {
  border-left: 4px solid orange;
  height: 70px;
  margin-right: 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
