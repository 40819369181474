@import '~antd/dist/antd.css';

.sitelayoutcontent {
  background-color: rgba(255, 255, 255, 0);
  padding: 24px;
  height: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.sitelayoutcontentregister {
  background-color: rgba(255, 255, 255, 0.5);
  padding-right: 20px;
  padding-top: 15px;
  padding-left: 20px;
  border-radius: 10px;
}

.header {
  background-color: rgba(255, 255, 255, 0);
}

.footer {
  width: 100%;
  height: 100%;
  display: flex;
  flexDirection: row;
  justifyContent: center;
  alignItems: center;
  background-color: rgba(0, 255, 255, 0);
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
