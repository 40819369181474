@import '~antd/dist/antd.css';

.sitelayoutcontentstart {
  background-color: rgba(255, 255, 255, 0);
  padding: 24px;
  height: 500px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.searchBar{
  width: 100vw;
  display: flex;
  padding: 30px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 5px 0px 5px  rgba(0, 0, 0, 0.6);
}

.headerstart {
  background-color: rgba(255, 255, 255, 0);
}

.footerstart {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, 0);
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
