.heading{
    font-size: 60px;
    font-family: Recoleta;
}

.impressum-heading {
    font-size: 23px;
    font-family: WorkSans;
    font-weight: bold;
    margin-top: 20px;
    z-index: 10;
}

.impressum {
    font-size: 23px;
    font-family: WorkSans;
    z-index: 10;
}

.gründer-heading {
    font-size: 40px;
    font-family: Recoleta;
}

.gründer-desc {
    font-size: 23px;
    font-family: WorkSans;
    text-align: center;
}

.dot {
  position: absolute;
  top: 20%;
  left: 0%;
  max-width: 10vw;
  max-height: 50vh;
  height: auto;
  width: auto;
  z-index:5;
}

@media screen and (max-width: 1200px),  (max-height: 700px) {
  .heading{
    font-size: 40px;
  }

    .impressum-heading {
      font-size: 17px;
    }

    .impressum {
      font-size: 17px;
    }
}

@media screen and (max-width: 900px),  (max-height: 500px) {
  .heading{
    font-size: 35px;
  }

      .impressum-heading {
        font-size: 15px;

      }

      .impressum {
        font-size:15px;

      }
}

@media screen and (max-width: 650px),  (max-height: 300px) {
  .heading{
    font-size: 25px;

  }

    .dot{
      display: none
    }

    .impressum-heading {
      font-size: 12px;

    }

    .impressum {
      font-size: 12px;

    }
}
