.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.logo {
   width: 220px;
}

.footer-headings {
  font-family: WorkSans;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-elements {
  font-size: 18px;
  font-family: WorkSans;
  margin-top: 25px;
  color: #25D2AA;
}

.section-heading {
  font-size: 40px;
  font-family: Recoleta;
}

.section-subheading {
  font-size: 30px;
  font-family: Recoleta;
}

.navbar {
  position: fixed;
  width: 100%;
  z-index:11;
}

.navbar-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
}

.section-text {
    font-size: 18px;
    font-family: WorkSans;
    text-align: center;
}

.main-heading {
  font-size: 75px;
  font-family: Recoleta;
}

.main-subheading {
  font-size: 28px;
  font-family: WorkSans;
}

.user-dot2 {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  max-height: 56%;
  max-width: 35%;
  width: auto;
  height: auto;
  top: 8%;
  z-index: 1;
}

.user-dot1{
  position: absolute;
  left: 0%;
  top: 4%;
  height: 30%;
  z-index: 1;
}

.user-dot3{
   position: absolute;
   right: 0%;
   top: 50%;
   height:30%;
   z-index: 1;
}

.user-path{
  position: absolute;
  left: 0%;
  top: 20%;
  width: 100%;
  z-index: 0
}

.user-element{
   position: absolute;
   right: 10%;
   top: 15%;
   max-height: 10%;
   max-width: 10%;
   width:auto;
   height: auto;
   z-index: 1;
}

.user-cat {
  position: absolute;
  left: 13%;
  max-height: 37vh;
 max-width: 20vw;
   width: auto;
   height: auto;
  top:41%;
  z-index: 5;
}

.phone-image {
  position: absolute;
  left: 47%;
  top: 25vh;
  z-index: 5;
  max-height: 65vh;
 max-width: 45vw;
   width: auto;
   height: auto;
}

@media screen and (max-width: 2600px),  (max-height: 1300px){
  .main-heading {
    font-size: 55px;
  }

  .main-subheading {
    font-size: 25px;
  }

}

@media screen and (max-width: 2000px),  (max-height: 1000px) {
  .main-heading {
    font-size: 50px;
  }

  .main-subheading {
    font-size: 20px;
  }

}

@media screen and (max-width: 1500px),  (max-height: 800px) {
  .main-heading {
    font-size: 40px;
  }

  .main-subheading {
    font-size: 15px;
  }

}

@media screen and (max-width: 1200px),  (max-height: 650px) {
  .main-heading {
    font-size: 34px;
  }

  .main-subheading {
    font-size: 12px;
  }

}

@media screen and (max-width: 950px){
  .section-heading {
    font-size: 30px;
    font-family: Recoleta;
  }

  .section-subheading {
    font-size: 25px;
    font-family: Recoleta;
  }

  .section-text {
      font-size: 15px;
      font-family: WorkSans;
  }

  .navbar {
    position: static;
  }

  .navbar-menu {
    display: none;
  }
}

@media screen and (max-width: 700px){
  .section-heading {
    font-size: 25px;
    font-family: Recoleta;
  }

  .section-subheading {
    font-size: 20px;
    font-family: Recoleta;
  }

  .section-text {
      font-size: 13px;
      font-family: WorkSans;
  }

}

@media screen and (max-width: 620px){
  .section-heading {
    font-size: 20px;
    font-family: Recoleta;
  }

  .section-subheading {
    font-size: 16px;
    font-family: Recoleta;
  }

  .section-text {
      font-size: 13px;
      font-family: WorkSans;
  }

  .main-heading {
    font-size: 30px;
  }

  .main-subheading {
    font-size: 16px;
  }

  .user-dot2 {
    max-height: 50%;
    max-width: 40%;

  }

  .user-dot1{
    position: absolute;
    left: 0%;
    top: 9%;
    height: 20%;
    z-index: 1;
  }

  .user-dot3{
     position: absolute;
     right: 0%;
     top: 50%;
     height:30%;
     z-index: 1;
  }

  .user-element{
     position: absolute;
     left: 20%;
     top: 60%;
     max-height: 10%;
     max-width: 10%;
     width:auto;
     height: auto;
     z-index: 1;
  }


}

@media screen and (max-width: 490px){
  .main-heading {
    font-size: 25px;
  }

  .main-subheading {
    font-size: 13px;
  }

}

@media screen and (max-width: 400px){


  .main-subheading {
    font-size: 10px;
  }
}

@media screen and (max-width: 1124px),  (max-height: 560px) {
  .user-cat {
    display: none;
  }
  .phone-image{
    display: none;
  }
}

@media screen and (max-height: 1200px){
    .phone-image {
      left: 50%;
    }
}

@media screen and (max-height: 950px){
    .logo {
        width:180px;
    }

    .phone-image {
      left: 53%;
    }
}

@media screen and (max-height: 700px) {

  .navbar {
    position: static;
  }

}

@media screen and (max-height: 510px) {
  .logo {
      width:150px;
  }

  .section-heading {
    font-size: 20px;
    font-family: Recoleta;
  }

  .section-subheading {
    font-size: 16px;
    font-family: Recoleta;
  }

  .section-text {
      font-size: 13px;
      font-family: WorkSans;
  }

  .main-heading {
    font-size: 20px;
  }

  .main-subheading {
    font-size: 9px;
  }
}
