.mapContainerMobile {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 400px;
  }
.mapContainer {
    position: relative;
    top: 0;
    bottom: 0;
    width: 600px;
    height: 1000px;
    }

    .sidebarStyle {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 40%;
      margin: 12px;
      z-index: 1 !important;
      font-weight: bold;
      }

      .marker {
        background-image: url('mapbox-icon.png');
        background-size: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
      }

      .mapboxgl-popup {
        max-width: 200px;
      }

      .mapboxgl-popup-content {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
      }
