.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.logo-mobile {
   width: 200px;
}

.footer-headings {
  font-family: WorkSans;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-elements {
  font-size: 18px;
  font-family: WorkSans;
  margin-top: 25px;
  color: #25D2AA;
}

.section-heading-mobile {
  font-size: 30px;
  font-family: Recoleta;
}

.section-subheading-mobile {
  font-size: 30px;
  font-family: Recoleta;
}

.navbar-mobile {
  width: 100%;
  z-index:11;
}

.section-text-mobile {
    font-size: 14px;
    font-family: WorkSans;
    text-align: center;
}

.main-heading-mobile {
  font-size: 26px;
  font-family: Recoleta;
}

.user-dot2-mobile {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  max-height: 56%;
  max-width: 39%;
  width: auto;
  height: auto;
  top: 9%;
  z-index: 1;
}

.user-dot1-mobile{
  position: absolute;
  left: 0%;
  top: 9%;
  height: 20%;
  z-index: 1;
}

.user-dot3-mobile{
   position: absolute;
   right: 0%;
   top: 50%;
   height:30%;
   z-index: 1;
}

.user-path-mobile{
  position: absolute;
  left: 0%;
  top: 20%;
  width: 100%;
  z-index: 0
}

.user-element-mobile{
   position: absolute;
   left: 40%;
   top: 60%;
   max-height: 10%;
   max-width: 10%;
   width:auto;
   height: auto;
   z-index: 1;
}

.user-cat-mobile {
  position: absolute;
  left: 0px;
  height: 185px;
  top:61vh;
  z-index: 5;
}

.phone-image-mobile {
  position: absolute;
  left: 30%;
  top:56vh;
  height: 258px;
  z-index: 5;
}
