@import '~antd/dist/antd.css';

.site-layout-content {
  background: #F2F2F2;
  overflow: auto;
  padding: 24px;
  min-height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
