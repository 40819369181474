.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Regular'), url(./fonts/WorkSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Bold'), url(./fonts/WorkSans-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Italic'), url(./fonts/WorkSans-Italic.ttf) format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Light'), url(./fonts/WorkSans-Light.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans-Medium'), url(./fonts/WorkSans-Medium.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Recoleta';
  src: local('Latinotype-RecoletaBold'), url(./fonts/Latinotype-RecoletaBold.otf) format('opentype');
}


.main-flexbox {
   margin-top: 3vh;
   max-width: 1000px;
   display: flex;
   z-index: 10;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
