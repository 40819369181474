.popup {
    right: 50%;
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* The actual popup */
  .popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
  }

  /* Popup arrow */
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Toggle this class - hide and show the popup */
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeInOut 5s linear forwards;
    animation: fadeInOut 5s linear forwards;
  }

  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeInOut {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }

  @keyframes fadeInOut {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
