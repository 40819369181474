.animal-paper-box  {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-content: stretch;
   margin-top: 15px;
   height: 200px;
   width: 300px;
}

.animal-paper-box:hover {
  opacity: 0.5;
}
