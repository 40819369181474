.verticalLineGreen {
    border-left: 6px solid #25D2AA;
    height: 25px;
    margin-right: 20px;
  }

  .horizontalLineMenu {
    border-bottom: 4px solid #25D2AA;
    width: 30px;
    margin-top: 8px;
  }